
import { defineComponent } from "vue";
import { useRemoveResponsible } from "@/graphql/responsible/remove.responsible";

export default defineComponent({
  name: "LeaveActivity",
  props: {
    id: String,
  },
  setup() {
    return {
      ...useRemoveResponsible(),
    };
  },
});
